<template>
  <Paginator
    :rows="limit"
    :totalRecords="_useListar.count"
    :rowsPerPageOptions="_useListar.pageOptions"
    :pageLinkSize="1"
    @page="onPage"
     />
</template>
<script setup>
import { computed, onMounted } from 'vue'
import { useListar } from '../../../store/listar.store'
const _useListar = useListar()
const limit = computed(() => _useListar._limit)
onMounted(() => {
  _useListar.listar()
})
const onPage = (e) => {
  _useListar.offset = e.first
  _useListar.listar()
}
</script>
